export { SearchPartsBanner as SearchBanner } from './SearchPartsBanner';
export {
  AdvantageCard,
  Categories,
  FeaturedLogo,
  FeaturedProducts,
  FormBanner,
  ProductCard,
  PromoBanner,
  SearchBar,
} from './marketing';
export { Carousel, Footer, Grid, Header, Heading, LoggedInPortal, Spacer } from './structure';

// List of blok relations to resolve when calling the story API
export const relations = ['Page.Header', 'Page.Footer'];
